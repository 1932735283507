import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Home = () =>  {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en'
      ? 'en'
      : (currentLanguage === 'en'
        ? 'en'
        : 'en');
    i18n.changeLanguage(newLanguage);
  };
  
  const [darkMode, setDarkMode] = useState(null);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    const bodyComputedStyle = window.getComputedStyle(document.body);
    const colorValue = bodyComputedStyle.getPropertyValue('--color').trim();
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    setDarkMode(colorValue === '#1a1a1a' ? false : prefersDarkMode);
  }, []);

  useEffect(() => {
    if (darkMode === null) return;
    document.documentElement.style.setProperty('--color', darkMode ? '#282C35' : '#1a1a1a');
    document.documentElement.style.setProperty('--button-color', darkMode ? '#ffffff' : '#ffffff');
    document.documentElement.style.setProperty('--text-color', darkMode ? '#ffffff' : '#ffffff');
  }, [darkMode]);

  return (
    <div className="flex items-center justify-center min-h-[90vh] my-[5vh] mx-7 text-[color:var(--text-color)]">
      <div className="w-full min-h-[90vh] gap-5 max-w-3xl sm:border-[2px] sm:border-[color:var(--text-color)] py-[2rem] sm:py-[5rem] px-5 sm:px-14 flex flex-col justify-between">
        <div className="font-[500]">
        <div className='flex gap-10'>
              <img src='./avatar.png' alt='avatar' className='rounded-md w-[30%] h-[30%]'></img>
                  <div>
                      <p className="sm:mt-16 font-bold text-4xl">WHRLBE</p>
                      <p className="mt-2 opacity-80">{t('about.tagline')}</p>
                  </div>
              </div>
              <p className="mt-8">{t('hero.intro')}</p>
          <p className="mt-4">{t('hero.services')}</p>
          <p className="mt-2">{t('hero.expertise')}</p>
          <p className="mt-2">{t('hero.clients')}</p>
          <p className="mt-2">{t('hero.goal')}</p>
          <div className="flex flex-inline gap-2 mt-4 items-start">
            <Link to="/projects">
                <button className="bg-[#54a1ff] rounded-md px-2 py-1 text-white font-medium">{t('buttons.projects')}</button>
            </Link>
            <Link to="/services">
                <button className="bg-[#54a1ff] rounded-md px-2 py-1 text-white font-medium">{t('buttons.services')}</button>
            </Link>
            </div>
            <div className="flex flex-inline gap-2 mt-4 items-start">
            <Link to="/about">
                <button className="bg-[#54a1ff] rounded-md px-2 py-1 text-white font-medium">{t('buttons.aboutUs')}</button>
            </Link>
            <a href="https://www.instagram.com/whrlbe">
                <button className="bg-[#fffc54] rounded-md px-2 py-1 text-black font-medium">{t('buttons.contactUs')}</button>
              </a>
            </div>
            <div className="flex  gap-2 mt-4 items-start">
                <button className="bg-[#3e4554] rounded-md px-4 py-1 text-white font-medium"
                  onClick={() => {
                    toggleLanguage();
                    alert("Language change is currently disabled!");
                  }}
                >
                  {currentLanguage === 'en' ? 'English' : (currentLanguage === 'sr' ? 'Serbian' : 'Georgian')}
                </button>
            <button button className="bg-[color:var(--button-color)] text-[color:var(--color)] rounded-md px-4 py-1 font-medium" onClick={toggleDarkMode}>{darkMode ? "light" : "dark"}</button>
          </div>
          <p className="mt-0 opacity-60">click to change</p>
        </div>
        <div className="flex gap-2 flex-wrap">
        <p className="mt-0 opacity-80"> check out :</p><a href="https://www.instagram.com/whrlbe">@instagram</a> <a href="https://www.tiktok.com/@whrlbe">@tiktok</a>
        </div>
      </div>
    </div>
  );
}

export default Home;
