import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en'
      ? 'en'
      : (currentLanguage === 'en'
        ? 'en'
        : 'en');
    i18n.changeLanguage(newLanguage);
  };

  const [showContent] = useState(true);
  const [darkMode, setDarkMode] = useState(null);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    const bodyComputedStyle = window.getComputedStyle(document.body);
    const colorValue = bodyComputedStyle.getPropertyValue('--color').trim();
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    setDarkMode(colorValue === '#282C35' ? true : prefersDarkMode);
  }, []);

  useEffect(() => {
    if (darkMode === null) return;
    document.documentElement.style.setProperty('--color', darkMode ? '#282C35' : '#1a1a1a');
    document.documentElement.style.setProperty('--button-color', darkMode ? '#ffffff' : '#ffffff');
    document.documentElement.style.setProperty('--text-color', darkMode ? '#ffffff' : '#ffffff');
  }, [darkMode]);

  return (
    <div className="flex items-center justify-center min-h-[90vh] my-[5vh] mx-2 sm:mx-7 text-[color:var(--text-color)]">
      <div className="w-full min-h-[90vh] gap-5 max-w-3xl py-[2rem] sm:py-[5rem] px-5 sm:px-14 flex flex-col justify-between">
        {showContent && (
          <div className="font-[500]">
            <div className='flex gap-10'>
              <div>
                <p className="sm:mt-16 font-bold text-2xl">{t('services.announcement')}</p>
                <p className="mt-2 opacity-80">{t('services.best_quality_for_affordable_price')}</p>
              </div>
            </div>
            <hr className='mt-10 mb-10' />
            <p className="mt-4 text-gray-200 ">
              {t('services.offer_details')}
              <br />
              {t('services.offer_disclaimer')}
              <br />
              <br />
              <span className="font-semibold">{t('services.standard_website')}</span> <span className="font-bold text-white"><br />{t('services.standard_website_price')}</span>
              <br />              <br />
              <span className="text-gray-300">{t('services.standard_website_description')}</span>
              <br />
              <br />
              <span className="font-semibold">{t('services.premium_website')}</span> <span className="font-bold text-white"><br />{t('services.premium_website_price')}</span>
              <br />              <br />
              <span className="text-gray-300">{t('services.premium_website_description')}</span>
              <br />
              <br />
              <span className="font-semibold">{t('services.standard_catalog')}</span> <span className="font-bold text-white"><br />{t('services.standard_catalog_price')}</span>
              <br />              <br />
              <span className="text-gray-300">{t('services.standard_catalog_description')}</span>
              <br />
              <br />
              <span className="font-semibold">{t('services.online_catalog')}</span> <span className="font-bold text-white"><br />{t('services.online_catalog_price')}</span>
              <br />              <br />
              <span className="text-gray-300">{t('services.online_catalog_description')}</span>
              <br />
              <br />
              <span className="font-semibold">{t('services.design')}</span> <span className="font-bold text-white"><br />{t('services.design_price')}</span>
              <br />              <br />
              <span className="text-gray-300">{t('services.design_description')}</span>
            </p>
          </div>
        )}
        <div className="flex gap-3">
          <Link to="/"><button className="bg-[#54a1ff] rounded-md px-3 py-1 text-white font-medium">{'<'}</button></Link>
          <button className="bg-[#3e4554] rounded-md px-4 py-1 text-white font-medium"
                  onClick={() => {
                    toggleLanguage();
                    alert("Language change is currently disabled!");
                  }}
                >
            {currentLanguage === 'en' ? 'English' : (currentLanguage === 'sr' ? 'Serbian' : 'Georgian')}
          </button>
          <button className="bg-[color:var(--button-color)] text-[color:var(--color)] rounded-md px-4 py-1 font-medium" onClick={toggleDarkMode}>
            {darkMode ? 'Light' : 'Dark'}
          </button>
        </div>
        <p className="mt-0 opacity-60">click to change</p>
      </div>
    </div>
  );
};

export default Services;