import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Projects = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en'
      ? 'en'
      : (currentLanguage === 'en'
        ? 'en'
        : 'en');
    i18n.changeLanguage(newLanguage);
  };

  const [darkMode, setDarkMode] = React.useState(null);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  React.useEffect(() => {
    const bodyComputedStyle = window.getComputedStyle(document.body);
    const colorValue = bodyComputedStyle.getPropertyValue('--color').trim();
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    setDarkMode(colorValue === '#1a1a1a' ? false : prefersDarkMode);
  }, []);
  
  React.useEffect(() => {
    if (darkMode === null) return;
    document.documentElement.style.setProperty('--color', darkMode ? '#282C35' : '#1a1a1a');
    document.documentElement.style.setProperty('--button-color', darkMode ? '#ffffff' : '#ffffff');
    document.documentElement.style.setProperty('--text-color', darkMode ? '#ffffff' : '#ffffff');
  }, [darkMode]);

  // Static data array
  const staticData = [
    { url: 'https://autopojasevi.rs', imageUrl: './websites/autopojasevi.rs.png', type: 'autopojasevi.rs' },
    { url: 'https://nikastars-edukativnicentar.com', imageUrl: './websites/nikastars-edukativnicentar.com.png', type: 'nikastars-edukativnicentar.com' },
    { url: 'https://rjtraveltrans.com', imageUrl: './websites/rjtraveltrans.com.png', type: 'rjtraveltrans.com' },
    { url: 'https://tsapartman.com/', imageUrl: './websites/tsapartman.com.jpg', type: 'tsapartman.com' },
    { url: 'https://pergoleincsystems.com/', imageUrl: './websites/pergolesystems.com.png', type: 'pergolesystems.com' },
    { url: 'https://rentacardndx.com/', imageUrl: './websites/rentacardndx.com.webp', type: 'rentacardndx.com' },
  ];

  // Fetch the localized project data
  const projects = staticData.map((item, index) => ({
    ...item,
    title: t(`projects.items.${index}.title`),
    description: t(`projects.items.${index}.description`)
  }));

  return (
    <div className="flex items-center justify-center min-h-[90vh] my-[5vh] mx-2 sm:mx-7 text-[color:var(--text-color)]">
      <div className="w-full min-h-[90vh] gap-5 max-w-3xl py-[2rem] sm:py-[5rem] px-5 sm:px-14 flex flex-col justify-between">
        <div className="font-[500]">
        <div className='flex gap-10'>
                <div>
                <p className="sm:mt-16 font-bold text-2xl">{t('disclaimer.title')}</p>
                <p className="mt-2 opacity-80">{t('disclaimer.subtitle')}</p>
                </div>
            </div>
            <hr className='mt-10 mb-10'></hr>
          {projects.map((item, index) => (
            <div key={index}>
              <div className='flex gap-10'>
                <a href={item.url} className='w-[30%] h-[30%]'>
                  <img src={item.imageUrl} alt={`project-${index}`} className='rounded-md' />
                </a>
                <div>
                  <p className="sm:mt-8 font-bold text-2xl">{item.title}</p>
                  <p className="opacity-70">{item.type}</p> {/* Ensure this field is mapped correctly */}
                  <p className="hidden sm:block mt-4 opacity-70 line-clamp-2 md:line-clamp-3">{item.description}</p>
                </div>
              </div>
              <p className="sm:hidden md:hidden font-[400] mt-5 opacity-70 line-clamp-4 md:line-clamp-3">{item.description}</p>
              <hr className='mt-10 mb-10' />
            </div>
          ))}
        </div>
        <div className="flex gap-3">
          <Link to="/">
            <button className="bg-[#54a1ff] rounded-md px-3 py-1 text-white font-medium">{'<'}</button>
          </Link>
          <button className="bg-[#3e4554] rounded-md px-4 py-1 text-white font-medium"
                  onClick={() => {
                    toggleLanguage();
                    alert("Language change is currently disabled!");
                  }}
                >
            {currentLanguage === 'en' ? 'English' : (currentLanguage === 'sr' ? 'Serbian' : 'Georgian')}
          </button>
          <button className="bg-[color:var(--button-color)] text-[color:var(--color)] rounded-md px-4 py-1 font-medium" onClick={toggleDarkMode}>
            {darkMode ? "light" : "dark"}
          </button>
        </div>
        <p className="mt-0 opacity-60">click to change</p>
      </div>
    </div>
  );
}

export default Projects;
